import Image from 'next/image';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { components } from '@/types/strapi';

export function CaseStudyCard({
	title,
	slug,
	image,
}: components['schemas']['CaseStudy']) {
	return (
		<Card className="group h-full overflow-hidden rounded-lg border-0 bg-white">
			<CardContent className="flex h-full flex-col p-0">
				<Link
					href={'/case-study/' + slug}
					className="relative block overflow-hidden">
					<div className="relative aspect-video overflow-hidden">
						<Image
							src={image?.url || '/placeholder.svg'}
							alt={image?.alternativeText || ''}
							width={740}
							height={400}
							className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-105"
							priority={true}
						/>
						<div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
					</div>
				</Link>
				<div className="flex flex-grow flex-col p-6">
					<h3 className="mb-4 line-clamp-2 text-xl font-bold">
						<Link
							href={'/case-study/' + slug}
							className="transition-colors hover:text-blue-600">
							{title}
						</Link>
					</h3>
					<div className="mt-auto">
						<Button
							asChild
							variant="outline"
							className="mt-2 w-full transition-colors duration-300 group-hover:bg-blue-600 group-hover:text-white">
							<Link
								href={'/case-study/' + slug}
								className="inline-flex items-center justify-center">
								<span>Download Case Study</span>
								<svg
									className="ml-2 h-4 w-4 transform transition-transform duration-300 group-hover:translate-x-1"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M14 5l7 7m0 0l-7 7m7-7H3"
									/>
								</svg>
							</Link>
						</Button>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
