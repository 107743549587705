import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import Link from 'next/link';
import { CaseStudyCard } from '@/components/case-study-card/CaseStudyCard';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';

type DynamicZoneCaseStudiesComponent =
	components['schemas']['DynamicZoneCaseStudiesComponent'];

interface CaseStudiesSectionProps extends DynamicZoneCaseStudiesComponent {
	case_studies: components['schemas']['CaseStudy'][];
}
// export function CaseStudiesSection() {
export const CaseStudiesSection = ({
	heading,
	sub_heading,
	case_studies,
}: CaseStudiesSectionProps) => {
	// If there are no case studies, don't render the section
	if (!case_studies || case_studies.length === 0) {
		return null;
	}

	// Determine if we should show the "View All" button
	const showViewAllButton = case_studies.length <= 3;

	return (
		<section className="bg-gradient-to-b from-gray-50 to-white py-16">
			<div className="container mx-auto px-4">
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
					className="mb-16 text-center">
					<h2 className="mb-4 bg-clip-text text-3xl font-bold">
						{heading}
					</h2>
					<p className="mx-auto mb-8 max-w-2xl text-xl text-gray-600">
						{sub_heading}
					</p>
					{showViewAllButton && (
						<Button
							asChild
							className="rounded-full bg-blue-600 px-6 py-2.5 shadow-md transition-all duration-300 hover:bg-blue-700 hover:shadow-lg">
							<Link
								href="/case-studies"
								className="inline-flex items-center">
								View All <ArrowRight className="h-4 w-4" />
							</Link>
						</Button>
					)}
				</motion.div>
				<div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
					{case_studies.map((study, index) => (
						<motion.div
							key={index}
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: index * 0.1 }}
							whileHover={{
								y: 0,
								transition: { duration: 0.2 },
							}}
							className="transform rounded-lg border transition-all duration-300 hover:shadow-xl">
							<CaseStudyCard {...study} />
						</motion.div>
					))}
				</div>
			</div>
		</section>
	);
};
